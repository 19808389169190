<template>
  <div id="container" style="height: 100%">

  </div>
</template>
<script>
import { getTaskInfo,previewFile } from '@/api/research/curriculum'
export default {
  name: 'PdfPreview',

  data(){
    return {
      pdfUrl:"",
    }
  },
  created: function () {
    this.getTaskInfo(this.$route.query.taskID)
  },
  methods: {
    async getTaskInfo (taskID) {
      const res = await getTaskInfo({
        id: taskID
      })
      this.previewFile(res.body.sourceId)
    },
    async previewFile(sourceId) {
      const res = await previewFile({
        sourceId: sourceId
      })

      this.$nextTick(()=> {
        var instance = aliyun.config({
          mount: document.querySelector('#container'),
          url: res.body.previewURL,

        })
        instance.setToken({
          token: res.body.accessToken,
        })
      })
    }
  }
}
</script>
<style>
.pdf {
  top: 0;
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
}
</style>
